import React from 'react'
import '../Styles.css'
// import Button from './common/Button'

const HeroSection = () => {
  return (
    <div className='hero-container'>
        {/* <video src="videos/video-2.mp4" autoPlay loop muted /> */}
        
          
            <div className="hero-btns">
                {/* <Button
                className="btns"
                buttonStyle='btn--outline'
                buttonSize='btn--large'
                >
                    GET  STARTED
                </Button> */}

                {/* <Button
                className="btns"
                buttonStyle='btn--primary'
                buttonSize='btn--large'
                >
                    WATCH TRAILER <i className="far fa-play-circle" />
                </Button> */}
            </div>       
      
    </div>
  )
}

export default HeroSection

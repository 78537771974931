import React from 'react'
import '../../App.css'
import Layout from '../navigation/Layout'

const SignUp = () => {
  return (
    <Layout>
    <div>
    <h1 className="services">SERVICES</h1>
    </div>
    </Layout>
  )
// return <h1 className="sign-up">SIGN UP</h1>
}

export default SignUp
